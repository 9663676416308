import React, { Component } from 'react';
import { Button, Card, Row, Col, Spin } from 'antd';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import './GradViz.css'

const API = axios.create({
    // baseURL: 'https://api.sprinkleai.com/'
    baseURL: 'http://192.168.1.105:9030/'
});

const ShowUploadedImage = (props) => {
    console.log(props.files)
    const width = props.width ? props.width : 320;
  
    const files = props.files;
    if (files && files.length > 0) {
      const preview = files[0].preview;
      return <img src={preview} className="Previewer"></img>
    } else {
      return <div></div>
    }  
  }
  
export default class DogClassifier extends Component {

    constructor(props) {
        super(props)
        this.state = {
            files: [],
            loading: false
        }        
    }

    onDrop(files) {
        this.setState({
          files
        });
        // console.log(`files: ${files[0]}`);
      }    

    classifyBreed() {
        const files = this.state.files;
        if (files) {
            this.setState({
                loading: true
            });

            try {
                let formData = new FormData();
                formData.append('file', files[0]);
                API.post('/classify', formData, {
                // API.post('/dogbreed/classify', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    // console.log(response.data);
                    this.setState({
                        loading: false
                    });
                }).catch(error => {
                    this.setState({
                        loading: false
                    });
                    console.error(`Failed to classify dog breed ${error}`);
                });
            }
            catch (error) {
                console.error(error);
            }
        }
    }

    render() {
        const { files, loading } = this.state;

        return (
            <Card>
                <Row>
                    <Col span={12}>
                        <Spin spinning={loading} delay={500}>
                            <Dropzone onDrop={this.onDrop.bind(this)} className="Dropzone">
                                {files.length == 0 &&  <p className="Dropzone-text">Drop image here or click to choose an image</p> }
                                {files.length > 0 &&  <ShowUploadedImage files={files} /> }
                            </Dropzone>
                        </Spin>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} style={{ marginTop: 5 }}>
                        <Button loading={loading} onClick={this.classifyBreed.bind(this)}>Classify</Button>
                        <Button>Clear</Button>
                    </Col>
                </Row>
            </Card>
        );
    }
}