import React from "react"
import { graphql } from "gatsby"
import { Divider } from 'antd';
import BlogPostChrome from "../../components/BlogPostChrome"
import Img from 'gatsby-image'
import { Row, Col, Button } from 'antd';
import DogClassifier from './dogclassifer';
import Layout from './../../components/Layouts';

export const frontmatter = {
  title: `Gradients of the Network vol. 2`,
  written: `2018-06-03`,
  updated: `2018-10-16`,
  layoutType: `post`,
  contentType: "blog",
  path: `/gradvizv2/`,
  category: `Deep learning`,
  image: './poster.jpg',
  description: `What you looking at and where you looking at ?`,
}


class GenerativeTextBase extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    // console.log(this.props.data)
    const architecturev1 = this.props.data.architecturev1.childImageSharp.resolutions
    const interstellar = this.props.data.interstellar.childImageSharp.resolutions

    return (
      <Layout data={this.props.data} location={this.props.location}>
      <BlogPostChrome {...this.props.data.javascriptFrontmatter}>
        <h1>Visualising Class Activation Mapping</h1>
        <article>
          <p>
            You painstakingly stack layers one after another, 
            carefully choosing the activation function, 
            debating whether to add batch norm before or after the activations 
            and whether to go fully convolutional or add a linear layer on top. 
            After several computational and mental epochs, the model is ready. 
            </p>
            <img src="https://sprinkle.nyc3.digitaloceanspaces.com/epochs/computemisc/com_epoch1.gif" />
            <p style={{marginTop: 10}}>
            You feed a perfectly centered cat picture and the model says ‘Yes, its a cat’. 
            You feed a dog picture and the model says 'its a dog’ and 
            then you feed a picture with the dog, cat, 
            and Snoopdog and your model goes 🤔 hmm "Insufficient data for a meaningful answer".
          </p>
        </article>
        <div>
          <Row>
            {/* <Col span={12}>
              <Card title='Try it yourself' style={{ marginRight: 10, background: "#ebeaee" }}>
                <div>
                  <DogClassifier />
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <p>
                You wonder if you could see parts of the image that contributed the most in your model’s decision.
                Enter Grad-CAM 😎
              </p>              
            </Col> */}
            <Col span={24}>
              <p>
                You wonder if you could see the parts of your image that contributed the most in your model’s decision.
                Enter Grad-CAM 😎
              </p>              
            </Col>            
            <Col span={24}>              
              <p>
                Gradient-weighted class activation mapping (Grad-CAM) allows us to visualise the parts of an input 
                image that contributed the most when the network made its prediction. 
                This is done by generating a heatmap and overlaying it over the input image.
                The following video shows the output of heatmap superimposed over a test video where the intensity of red color shows where the model is focussing at.
                The video was generated using imagenet trained VGG16 network without any finetuning, as you can make out from the predicted classes.
              </p>
              <div style={{textAlign: "center", margin: "0 auto"}}>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/fwQybTttaNA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>              
                {/* <video className="video-container video-container-overlay" autoPlay={false} muted controls>
                  <source type="video/mp4" data-reactid=".0.1.0.0.0" src="https://sprinkle.nyc3.digitaloceanspaces.com/gradcam/GradCAMv2.mp4" />
                </video> */}
                <p className="PreviewText">Background score was generated using an RNN generative model</p>
              </div>
              
              <p>
                So, how do we generate the heatmap ?               
              </p>
              <ul>
                <li>
                  <b>Step 1: Obtain the gradient</b> of predicted class with respect to feature maps of last convolutional layer. 
                  Why last convolutional layer, because they capture the highest representation construct.
                </li>
                <li>
                  <b>Step 2: Compute the global average pool</b> of gradients to 
                  capture the importance of feature map for the predicted class
                </li>
                <li>
                  <b>Step 3: Generate the heatmap</b> by multiplying pooled gradients 
                  with feature map for each channel, taking channelwise mean and 
                  passing the output through RELU. RELU will discard the 
                  negative gradients, i.e the gradients that do not influence predicted class.                  
                  </li>
                <li>
                  <b>Step 4: Resize the heatmap</b> to the size of the input image
                </li>
                <li>
                  <b>Step 5: Overlay the heatmap</b> over input image by blending it with desired opacity</li>
              </ul>
              <p style={{marginTop: 10}}>
            Grad-CAM is a generalisation of CAM visualisation technique proposed by <a href="https://arxiv.org/abs/1512.04150">this paper</a>.
            In order to implement the techniques proposed in CAM paper, 
            we need to modify the network architecture by replacing dense layers 
            with global average pooling layer to capture class-specific feature maps.
            This may be undesirable because the change will impact prediction accuracy
            and will also require retraining.

            Grad-CAM offers the generalisation of CAM techniques without any 
            modifications to the network architecture and no retraining.
        </p>       
          <Row>                
              <Button type="primary" icon="code" size="large" href="https://colab.research.google.com/drive/1AG4MbfaMQGARlGMzjanWnlqcroxhRTVw">Review annotated GRAD-CAM notebook</Button>          
              <Button type="primary" style={{marginLeft: 10}} icon="code" size="large" href="https://colab.research.google.com/drive/1AG4MbfaMQGARlGMzjanWnlqcroxhRTVw">CAM notebook</Button>
          </Row>

            </Col>
          </Row>
        </div>
        <Divider />
        <h2>Conclusion</h2>
        <p>
          We saw a simple technique, Grad-CAM, to visualise the activation maps for a specific class.
          This technique can be used to visually inspect the parts that influence the network predictions the most.
          And hopefully, make the CNN predictions a bit more transparent.
        </p>
        <Divider />
        <Row>
          {/* <Col span={4}>
          Additional Resources:
          </Col>
          <Col span={20}>
          </Col> */}
          <Col span={4} className="references-header">
            References:
          </Col>
          <Col span={20} className="references-text">
            <ol>
              <li>
                Grad-CAM: Visual Explanations from Deep Networks via Gradient-based Localization
                &nbsp;[<a href="https://arxiv.org/abs/1610.02391">Arxiv</a>]
              </li>
              <li>
                Learning Deep Features for Discriminative Localization
                &nbsp;[<a href="http://cnnlocalization.csail.mit.edu/">website</a>,
                &nbsp;<a href="https://arxiv.org/abs/1512.04150">Arxiv</a>]
              </li>
            </ol>
          </Col>
        </Row>
      </BlogPostChrome>
      </Layout>
    )
  }
}

export default GenerativeTextBase

const styles = {}

styles.row = {
  display: `flex`,
  flexWrap: `wrap`,
  margin: `8px -4px 1rem`,
}

// We want to keep this component mostly about the code
//  so we write our explanation with markdown and manually pull it in here.
//  Within the config, we loop all of the markdown and createPages. However,
//  it will ignore any files appended with an _underscore. We can still manually
//  query for it here, and get the transformed html though because remark transforms
//  any markdown based node.
export const pageQuery = graphql`
    query gradvizqueryv2($slug: String!) {
      markdownRemark(
        fields: { slug: { eq: "/2018-02-14-sentiment-v1/_gentext/" } }
      ) {
        html
      }
      javascriptFrontmatter(fields: { slug: { eq: $slug } }) {
        ...JSBlogPost_data
      }
      architecturev1: file(
        relativePath: {regex: "/sentiment_v1_arch.png/"}) {
        childImageSharp {
          resolutions(width: 119, height: 300) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
      interstellar: file(
        relativePath: {regex: "/Interstellar.png/"}) {
        childImageSharp {
          resolutions(width: 1024, height: 475) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
    }
  `
